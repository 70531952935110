import { Injectable } from '@angular/core';
// import { ErrorDialogService } from '../error-dialog/errordialog.service';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpHeaders
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

/**
 * The http interceptor to help with middle-ware routing in dev, test, and prod environments
 */
@Injectable()
export class HttpConfigProspectingLoginInterceptor implements HttpInterceptor {
  /**
   * @ignore
   */
  constructor() { }

  /**
   * The function for intercepting http requests
   * @param {HttpRequest<any>} request The request
   * @param {HttpHandler} next THe handler for when the intercept is done
   * @return {Observable<HttpEvent<any>>} The event returned
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = new HttpHeaders({
      'If-Modified-Since': 'Mon, 26 Jul 1997 05: 00: 00 GMT',
      'Cache-Control': 'no-cache',
      'Pragma': 'no-cache'
    });
    const token: string = localStorage.getItem('token');
    // don't add token to login request
    const re = /_vti_bin/gi;
    if (request.url.search(re) === -1) {
      if (token) {
        headers = headers.append('Authorization', 'Bearer ' + token);
      }

      // request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    }
    request = request.clone({ headers });

    // console.log(request.headers);
    const oldAPIMarker = /\.svc/gi;
    if (environment.production) {
      if (request.url.search(oldAPIMarker) === -1 && environment.apiBaseUrlV4) {
        request = request.clone({ url: `${environment.apiBaseUrlV4}${request.url}` });
      }
    } else {
      if (request.url.search(oldAPIMarker) === -1 && environment.apiBaseUrlV4) {
        request = request.clone({ url: `${environment.apiBaseUrlV4}${request.url}` });
      } else if (environment.apiBaseUrl) {
        request = request.clone({ url: `${environment.apiBaseUrl}${request.url}` });
      }
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
         // console.log('event--->>>', event);
          // this.errorDialogService.openDialog(event);
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        let data = {};
        data = {
          reason: error && error.error && error.error.reason ? error.error.reason : '',
          status: error.status
        };
        // this.toastrService.error(error.message);
        return throwError(error);
      }));
  }
}
