import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {LoginService} from "./login.service";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'prospecting-login';
  private globalLoading: boolean;
  loadingClassName: string;

  constructor(private router: Router, private serv: LoginService, private dialog: MatDialog) {
    router.events.subscribe((res) => {
      if (res instanceof NavigationEnd) {

      }
    });
  }
  ngOnInit() {
    this.serv.globalLoading$.subscribe((r: boolean) => {
      this.globalLoading = r;
      setTimeout(() => {
        this.loadingClassName = this.isLoadingOn();
      }, 10);
    });
  }

  isLoadingOn(): string {
    return this.globalLoading ? 'on' : '';
  }


  /**
   * Open a modal window with the disclaimer
   * @param event the click event
   */
  showDisclaimer(event) {
    event.preventDefault();
    const windowConfig = new MatDialogConfig();
    windowConfig.autoFocus = true;
    windowConfig.id = 'disclaimer-dialog';
    windowConfig.width = '80%';
    windowConfig.maxWidth = '1200px';
    windowConfig.disableClose = false;
    windowConfig.backdropClass = 'lightBackdrop';
    windowConfig.disableClose = true;
    const window = this.dialog.open(DisclaimerComponent, windowConfig);
  }
}

@Component({
  selector: 'app-disclaimer',
  templateUrl: 'components/disclaimer/disclaimer.component.html',
  styleUrls: ['./app.component.scss']
})
export class DisclaimerComponent  {

  constructor(private dialogRef: MatDialogRef<DisclaimerComponent>) {

  }
  closeWindow(): void {
    this.dialogRef.close();
  }
}

