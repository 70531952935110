<header class="app-header">
  <img src="../assets/logo.png" class="logo" routerLink="/" />

</header>

<div class="main-content">

  <router-outlet></router-outlet>

</div>

<footer>
  <div class="footerWrapper" role="contentinfo" aria-label="my janney footer legal information and documents">
    <div class="footerContainer">
      <div class="footerSubContainer">
        <p class="ulHeader">Legal</p>
        <ul class="ulFooter opacity">
          <li>
            <a href="https://www.janney.com/terms--conditions" target="_blank">Terms & Conditions</a>
          </li>
          <li>
            <a href="https://www.janney.com/privacy-policy" target="_blank">Privacy Policy</a>
          </li>
          <li>
            <a href="https://www.janney.com/investment-disclosures" target="_blank">Investment Disclosures</a>
          </li>
          <li>
            <a href="" (click)="showDisclaimer($event)" target="_blank">Disclaimer</a>
          </li>

        </ul>
      </div>
      <div class="footerSubContainer">
        <p class="ulHeader">About Janney</p>
        <ul class="ulFooter opacity">
          <li>
            <a href="https://www.janney.com/financial-information" target="_blank">Financial Statements</a>
          </li>
          <li>
            <a href="https://www.janney.com/business-continuity" target="_blank">Business Continuity</a>
          </li>
        </ul>
      </div>

      <div class="footerSubContainer">
        <p class="ulHeader">Safeguards</p>
        <ul class="ulFooter opacity">
          <li>
            <a target="_blank" href="https://www.janney.com/individuals--families/resources--education/account-information/janney-data-security">Data Security</a>
          </li>
        </ul>
      </div>
      <div class="footerSubContainer footerDivider"></div>
      <div class="footerSubContainer">
        <ul class="ulFooter opacity">
          <li>
            <a href="https://www.facebook.com/janneymontgomeryscott/"><img src="/assets/iconsX2/facebook-176.png"
                                                                           srcset="/assets/iconsX2/facebook-176@2x.png 2x"
                                                                           class="facebook-176 iconMargin"></a>
            <a href="https://twitter.com/Janney1832"><img src="/assets/iconsX2/twitter-154.png"
                                                          srcset="/assets/iconsX2/twitter-154@2x.png 2x"
                                                          class="twitter-154 iconMargin"></a>
            <a href="https://www.linkedin.com/company/janney-montgomery-scott/"><img src="/assets/iconsX2/linkedin-161.png"
                                                                                     srcset="/assets/iconsX2/linkedin-161@2x.png 2x"
                                                                                     class="linkedin-161 iconMargin"></a>
          </li>
          <li class="iconTopMargin numberMarginTop">
            <img src="/assets/iconsX2/call-192.png"
                 srcset="/assets/iconsX2/call-192@2x.png 2x"
                 class="call-192">
            <span class="opacity noUnderline">
                <a href="tel:1-888-882-0012">1-888-882-0012</a>
              </span>
          </li>
          <li class="iconTopMargin">
            <img src="/assets/iconsX2/email-1572.png"
                 srcset="/assets/iconsX2/email-1572@2x.png 2x"
                 class="email-1572">
            <span class="opacity">
                 <a href="mailto:onlineservices@janney.com">onlineservices@janney.com</a>
              </span>
          </li>
          <li class="iconTopMargin">
            <img src="/assets/iconsX2/location.svg" class="location">
            <span class="opacity">
                <a href="https://www.janney.com/about-janney/office-locations/search" target="_blank">Our Locations</a>
              </span>
          </li>
        </ul>
      </div>
    </div>
  </div>

</footer>

<div id="loading-screen" [ngClass]="loadingClassName" role="alertdialog"></div>
<div id="loading-dialog" [ngClass]="loadingClassName">
  <h1>Loading...</h1>
  <div class="lds-ring" [ngClass]="{'loadingRingOn': loadingClassName === 'on'}"><div></div><div></div><div></div><div></div></div>
</div>
