<div class="container">
  <div>
    <h3>Welcome to Janney, please log in</h3>
    <mat-card>
      <mat-card-content>
        <mat-horizontal-stepper #stepper [disableRipple]="true">
          <mat-step label="Enter username and password" [stepControl]="loginForm" [editable]="false">
            <form *ngIf="!forgot" [formGroup]="loginForm" (submit)="submitLogin($event)">
              <mat-form-field>
                <mat-label>Username</mat-label>
                <input matInput type="text" autocomplete="username" #user id="username" required formControlName="username" />
              </mat-form-field><br/>
              <mat-form-field>
                <mat-label>Password</mat-label>
                <input matInput type="password" autocomplete="current-password" id="password" required formControlName="password" />
              </mat-form-field>
              <br/>

              <mat-checkbox id="rememberMe" labelPosition="after" [formControlName]="'rememberMe'">
                Remember me <small>(do not select this option for public computers)</small>
              </mat-checkbox>
              <p class="error-text" *ngIf="errorMessage">{{errorMessage}}</p>
              <div class="form-footer">
                <div>
                  <button mat-button type="button" class="no-style link-underline" (click)="forgotUsernamePassword()">Forgot your password?</button>
                </div>
                <div class="text-right">
                  <input type="submit" class="btn btn-primary" value="Login" [disabled]="loginForm.invalid" />
                </div>
              </div>
            </form>
            <form *ngIf="forgot" [formGroup]="forgotForm" (submit)="submitForgot($event)">
              <h4>Forgot password</h4>
              <p>Enter your username below.</p>
              <mat-form-field>
                <mat-label>Username</mat-label>
                <input matInput autocomplete="username" type="text" id="forgotUsername" #forgot class="form-control" required formControlName="username" />
              </mat-form-field>
              <p class="error-text" *ngIf="forgotErrorMessage">{{forgotErrorMessage}}</p>
              <div class="text-right">
                <button type="button" class="no-style link-underline btn-inline" (click)="cancelForgotUsername()">Back to Login</button>
                <input type="submit" class="btn btn-primary btn-inline" value="Submit" [disabled]="forgotForm.invalid" />
              </div>
            </form>
          </mat-step>
          <mat-step label="Select authentication method">
            <form [formGroup]="selectAuthForm" (submit)="selectAuth($event)">
              <h4>Select an authentication device and method</h4>
              <mat-radio-group label="Select and authentication device and method" [formControlName]="'auth'" [required]="true">
                <div *ngFor="let d of deviceList" style="margin-bottom: 10px;">
                  <strong>{{d.value | phoneNumber}}</strong><br/>
                  <mat-radio-button *ngIf="d.capabilities.indexOf('sms') >= 0" [value]="d.id + '|sms'" style="margin-right: 20px;">Text</mat-radio-button>
                  <mat-radio-button [value]="d.id + '|call'">Call</mat-radio-button>
                </div>
              </mat-radio-group>
              <div class="text-right">
                <input type="submit" [disabled]="selectAuthForm.invalid" class="btn btn-primary" value="Select" />
              </div>
            </form>
          </mat-step>
          <mat-step label="Enter authentication code" [editable]="false">
            <p id="pinLabel">We have sent a PIN using the method you selected. Please enter PIN below to verify your identity.</p>
            <form [formGroup]="authForm" (submit)="sendPIN($event)">
              <mat-form-field>
                <mat-label>PIN</mat-label>
                <input matInput #pin type="text" id="PIN" maxlength="6" name="PIN" formControlName="pin" pattern="^[0-9]{6}$" required />
              </mat-form-field><br/>
              <mat-form-field *ngIf="forgot">
                <mat-label>New password</mat-label>
                <input matInput type="password" autocomplete="new-password" formControlName="newPassword" pattern="^(?=(?:.*?[!@#$%^&*()_]){1})(?=(?:.*?[A-Za-z]){1})(?=(?:.*?[0-9]){1})[A-Za-z0-9!@#$%^&*()_]{7,20}$" required />
              </mat-form-field><br/>
              <mat-form-field *ngIf="forgot">
                <mat-label>Confirm password</mat-label>
                <input matInput type="password" autocomplete="new-password" formControlName="confirmPassword" must-match="" pattern="^(?=(?:.*?[!@#$%^&*()_]){1})(?=(?:.*?[A-Za-z]){1})(?=(?:.*?[0-9]){1})[A-Za-z0-9!@#$%^&*()_]{7,20}$" required />
              </mat-form-field>
              <label for="PIN"></label>

              <small class="error-text" style="display: block;" *ngIf="pinFail > 0">
               Incorrect PIN entered. (attempts: {{pinFail}})</small>
              <small class="error-text" style="display: block;" *ngIf="authForm.errors && authForm.errors.mustMatch">
                Password fields must match.</small>
              <div class="text-right">
                <button type="button" class="btn btn-default btn-inline" (click)="stepperBack()">Back</button>
                <input type="submit" [disabled]="authForm.invalid" class="btn btn-primary btn-inline" value="Submit" />
              </div>
            </form>
          </mat-step>
        </mat-horizontal-stepper>

      </mat-card-content>
    </mat-card>
    <div class="flex-box centered-h">
      <p class="mat-small">
        If you do not have a Janney Online Access Prospect Portal login, please contact your Janney Financial Advisor to receive a registration link.
        For more information about Janney, please visit <a href="https://www.janney.com" class="link-underline">www.janney.com</a>.
      </p>
    </div>
  </div>

</div>
