<div class="disclaimerContainer">
  <h3 role="navigation" class="text-right">
    <button class="no-style" (click)="closeWindow()"><span class="xtext">x</span></button>
  </h3>
  <p>Janney investment professionals are available to discuss the suitability and risks involved with various financial products and strategies. We will be happy to provide a prospectus, when available, and other information upon request. We will not accept orders online. If you send us an e-mail, DO NOT INCLUDE TRADING INSTRUCTIONS. Should the viewer leave this site via a link, the existence of such links should not be construed as an endorsement, approval or verification by Janney Montgomery Scott LLC of any content available on third party sites.</p>

  <p>Investor Communications and other materials will be presented in "pdf" file format, and you may need to have Adobe Reader installed on your computer. Download and install Adobe Reader at the <a href="http://www.adobe.com/downloads" target="_blank">Adobe website</a>.</p>

  <p>This site has been published in the United States for residents of the United States.</p>

  <p>Copyright &copy; 2019 Janney Montgomery Scott LLC. All Rights Reserved.</p>

  <p>Member: NYSE FINRA SIPC</p>

<p class="">Please note market data is delayed by at least 20 minutes</p>
</div>
