import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import {AppComponent, DisclaimerComponent} from './app.component';
import { HomeComponent } from './components/home/home.component';
import {MyMaterialModule} from "./material.module";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {HttpConfigProspectingLoginInterceptor} from "./interceptors/httpconfig.interceptor";
import {DigitalFingerprintService} from "./digital-fingerprint.service";
import {LoginService} from "./login.service";
import {FormsModule} from "@angular/forms";
import {CookieService} from "ngx-cookie-service";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {PhoneNumberPipe} from "./pipes/phone.pipe";
import { GenericDialogComponent } from './components/generic-dialog/generic-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PhoneNumberPipe,
    GenericDialogComponent,
    DisclaimerComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MyMaterialModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [
    DigitalFingerprintService,
    LoginService,
    CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: HttpConfigProspectingLoginInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
