import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, ObservedValueOf} from "rxjs/index";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AuthResponse, LoginResponse} from "./classes";
import {DigitalFingerprintService} from "./digital-fingerprint.service";

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  /** Is something loading for which we need the loading screen */
  public globalLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public username: string;

  constructor(private http: HttpClient, private df: DigitalFingerprintService) { }


  login(obj): Observable<LoginResponse> {
    if (obj) {
      obj.DfpData = this.df.val;
    }
    return this.http.post<LoginResponse>(`/_vti_bin/jmslogin.svc/AuthenticateDfp`, obj);
  }

  selectAuth(obj): Observable<any> {
    if (obj) {
      obj.DfpData = this.df.val;
    }
    return this.http.post<any>(`/_vti_bin/jmslogin.svc/SendUserOTP`, obj);
  }

  selectAuthForgotPassword(obj): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders({
      contentType: "application/json; charset=utf-8",
      dataType: "json"});
    return this.http.post<any>(`/_vti_bin/JMSRegistration.svc/SendPwdMFA`, obj, { headers: headers});
  }

  authenticate(obj): Observable<AuthResponse> {
    if (obj) {
      obj.DfpData = this.df.val;
    }
    return this.http.post<AuthResponse>(`/_vti_bin/jmslogin.svc/ProcessAuthentication`, obj);
  }

  authenticateForgotPassword(obj): Observable<any> {
    return this.http.post<any>(`/_vti_bin/JMSRegistration.svc/Updatepwd`, obj);
  }

  forgotPassword(obj): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders({
      contentType: "application/json; charset=utf-8",
      dataType: "json"}
      );
    return this.http.post<any>(`/_vti_bin/JMSRegistration.svc/GetMFA`, obj, { headers: headers});
  }
}
