import {FormGroup, ValidationErrors, ValidatorFn} from "@angular/forms";
import {ValidationError} from "json-schema";

export class LoginResponse {
  acctNums: null;
  data: {
    DeviceList: Device[];
    Status: string;
    Token: string;
  };
  message: string;
}

export class Device {
  capabilities: string[];
  id: string;
  type: string;
  value: string;
}

export class AuthResponse {
  data: boolean;
  message: string;
}

export function MustMatchValidator(controlName: string, matchingControlName: string): ValidatorFn {
  return (fg: FormGroup): ValidationErrors | null => {
    const control = fg.controls[controlName];
    const match = fg.controls[matchingControlName];
    if (!control || !match || control.invalid) {
      return null;
    }
    if (control.value !== match.value) {
      return {mustMatch: true};
    } else {
      return null;
    }
  };
}
