import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to display digit strings as phone numbers
 */
@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  /** @ignore */s
  transform(value: string, args?: any): any {

      const reg = /^\d{10}$/,
      guestReg = /^\(\d{3}\)\d{3}-\d{4}$/;
      // guest access has a different expected regexp
      if (reg.exec(value)) {
        return '(' + value.substring(0, 3) + ') ' + value.substring(3, 6) + '-' + value.substr(6);
      } else if (guestReg.exec(value)) {
        return value.substr(0, 5) + ' ' + value.substr(5);
      } else {
        return value;
      }

  }

}
