import {Component, ContentChild, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, ValidatorFn} from "@angular/forms";
import {CookieService} from "ngx-cookie-service";
import {LoginService} from "../../login.service";
import {HttpErrorResponse} from "@angular/common/http";
import {AuthResponse, Device, LoginResponse, MustMatchValidator} from "../../classes";
import {MatStepper} from "@angular/material/stepper";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {GenericDialogComponent} from "../generic-dialog/generic-dialog.component";




@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public loginForm = new FormGroup({
    username: new FormControl(``),
    password: new FormControl(''),
    isPublic: new FormControl(false),
    rememberMe: new FormControl({value: false, disabled: false})
  });
  public forgotForm = new FormGroup({
    username: new FormControl(``)
  });
  public selectAuthForm = new FormGroup({
    auth: new FormControl('')
  });
  public authForm = new FormGroup({
    pin: new FormControl(''),
    newPassword: new FormControl(''),
    confirmPassword: new FormControl('')
  }, MustMatchValidator('newPassword', 'confirmPassword'));
  public errorMessage: string;
  public forgotErrorMessage: string;
  public deviceList: Device[];
  private token: string;
  public forgot = false;
  public pinFail = 0;
  fakeDevices: Device[] = [
    (<Device>{capabilities: ['sms', 'call'], id: '2', type: 'foo', value: '6099220775'}),
    (<Device>{capabilities: ['call'], id: '3', type: 'bar', value: '1234566778'})
  ]
  @ViewChild('user') userElement: ElementRef;
  @ViewChild('forgot') forgotElement: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('pin') pin: ElementRef;

  constructor(private cs: CookieService,
              private ls: LoginService,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    if (this.cs.get('jms_username')) {
      this.loginForm.patchValue({username: this.cs.get('jms_username'), rememberMe: true});
    }
  }


  forgotUsernamePassword(): void {
    this.forgot = true;
  }


  stepperBack(): void {
    this.stepper.previous();
  }

  submitLogin(e: Event): void {
    if (!this.loginForm.valid) {
      e.preventDefault();
      return;
    }
    this.errorMessage = '';
    if (this.loginForm.value.rememberMe) {
      this.cs.set('jms_username', this.loginForm.value.username);
    } else {
      this.cs.delete('jms_username');
    }
    this.ls.globalLoading$.next(true);
    this.ls.login({
      Username: this.loginForm.value.username,
      pwd: this.loginForm.value.password,
      isPrivate: this.loginForm.value.rememberMe
    }).subscribe((res: LoginResponse) => {
      this.ls.globalLoading$.next(false);
      if (res.data.Status.toUpperCase() === 'INVALID') {
        this.errorMessage = res.message || 'Login failed, please check your credentials and try again.';
        this.userElement.nativeElement.focus();
      } else if (res.data.Status.toUpperCase() === 'LOCKED') {
        this.errorMessage = res.message || 'This account has been locked. Please contact your financial advisor.';
        this.userElement.nativeElement.focus();
      } else if (res.data.Status === `TwoFactor`) {
        this.deviceList = res.data.DeviceList;
        this.token = res.data.Token;
        this.stepper.next();
      } else if (res.data.Status === `SkipTwoFactor`) {
        this.token = res.data.Token;
        this.ls.globalLoading$.next(true);
        this.ls.authenticate({
          "authToken": this.token
        }).subscribe(() => {
          document.location.pathname = '/';
        }, () => {
          document.location.pathname = '/';
        });
      }
    }, (err: any) => {
      this.ls.globalLoading$.next(false);
      this.errorMessage = 'This username and password combination did not match our records. Please try again.';
      this.userElement.nativeElement.focus();
    });

  }

  submitForgot(e: Event): void {
    if (this.forgotForm.invalid) {
      e.preventDefault();
      return;
    }
    this.ls.globalLoading$.next(true);
    this.ls.forgotPassword({
      "Username": this.forgotForm.value.username
    }).subscribe((res: any) => {
      this.ls.globalLoading$.next(false);
      this.deviceList = res['data'] as Device[];
      this.stepper.next();
    }, (err: any) => {
      this.ls.globalLoading$.next(false);
      this.forgotErrorMessage = err.error.message ||
        `We're sorry, we could not find that username.
        Please try again. If you believe that you are receiving this message in error, contact your financial advisor.`;
      this.forgotElement.nativeElement.focus();
    });
  }
  cancelForgotUsername(): void {
    this.forgotForm.patchValue({username: ''});
    this.forgot = false;
  }

  selectAuth(e: Event): void {
    this.ls.globalLoading$.next(true);
    let d = this.selectAuthForm.value.auth.split('|');
    this.stepper.next();
    if (this.forgot) {
      this.ls.selectAuthForgotPassword({
        "Username": this.forgotForm.value.username,
        "Device": d[0],
        "otpmethod": d[1]
      }).subscribe((res: any) => {
        this.ls.globalLoading$.next(false);
        this.stepper.next();
      }, err => {
        this.ls.globalLoading$.next(false);
        this.dialog.open(GenericDialogComponent, {
          data: {
            title: 'Error!',
            body: `We're sorry, something seems to have gone wrong. Please try again.`
          }
        });
      });
    } else {
      this.ls.selectAuth({
        "authToken": this.token,
        "Device": d[0],
        "otpmethod": d[1]
      }).subscribe((res: any) => {
        this.ls.globalLoading$.next(false);
        this.stepper.next();
      }, (err: HttpErrorResponse) => {
        this.ls.globalLoading$.next(false);
        this.dialog.open(GenericDialogComponent, {
          data: {
            title: 'Error!',
            body: `We're sorry, something seems to have gone wrong. Please try again.`
          }
        });
      });
    }
  }

  sendPIN(e: Event): void {
    this.ls.globalLoading$.next(true);
    let d = this.selectAuthForm.value.auth.split('|');
    if (this.forgot) {
      this.ls.authenticateForgotPassword({
        "Username": this.forgotForm.value.username,
        "Device": d[0],
        "userResponse": this.authForm.value.pin,
        "newPwd": this.authForm.value.newPassword
      }).subscribe((res: any) => {
        this.ls.globalLoading$.next(false);
        this.dialog.open(GenericDialogComponent, {data: {
            title: 'Success',
            body: 'Your password has been updated.'
          }}).afterClosed().subscribe(() => {
            window.location.reload();
        });
      },(err: HttpErrorResponse) => {
        this.ls.globalLoading$.next(false);
        this.pinError();
        this.pin.nativeElement.focus();
      });
    } else {
      this.ls.authenticate({
        "authToken": this.token,
        "factorResponse": this.authForm.value.pin
      }).subscribe((res: AuthResponse) => {
        if (res.data) {
          document.location.pathname = '/';
        } else {
          this.pinError();
          this.pin.nativeElement.focus();
        }
        this.ls.globalLoading$.next(false);
      }, (err: HttpErrorResponse) => {
        this.ls.globalLoading$.next(false);
        this.pinError();
        this.pin.nativeElement.focus();
      });
    }
  }

  pinError(): void {
    this.pinFail++;
    if (this.pinFail >= 3) {
      this.dialog.open(GenericDialogComponent, {
        data: {
          title: 'Authentication failed',
          body: 'You have failed three times to enter your PIN. This page will now reload and you may begin the process anew.'
        }
      }).afterClosed().subscribe(_ => {
        window.location.reload();
      });
      setTimeout(() => {
        window.location.reload();
      }, 5500);
    }
  }
}
